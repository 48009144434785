<template>
  <section>
    <div>
      <div class="w-full md:flex gap-2">
        <div>
          <label class="text-xs text-gray-600" for="valor_unitario">Modalidad de contrato</label>
          <Dropdown
            filter
            panelClass="text-sm"
            v-model="contratosStore.modalidad_contrato_seleccionada"
            @change="cambioModalidadContrato"
            :options="contratosStore._listado_modalidades_contrato"
            placeholder="Seleccione"
            optionLabel="descripcion"
            optionValue="value"
            class="w-full"
            inputClass="text-xs"
            :inputStyle="{ 'padding': '0.65rem' }"
          />
        </div>
        <div>
          <label class="text-xs text-gray-600" for="valor_unitario">Motivo de autorización</label>
          <Dropdown
            filter
            panelClass="text-sm"
            @change="cambioMotivoAutorizacion"
            v-model="motivoAutorizacionSeleccionado"
            :options="listadoMotivosAutorizacion"
            placeholder="Seleccione"
            optionLabel="Nombre"
            class="w-full"
            :inputStyle="{ padding: '0.65rem' }"
            inputClass="text-xs"
          />
          <span v-if="contratosStore.creando && !contratosStore._header.MotivosAutorizacion.length" class="text-xs text-red-400 italic">Este campo es requerido</span>
        </div>
      </div>
      <div class="mt-4" v-if="contratosStore._header.MotivosAutorizacion.length">
        <DataTable :showGridlines="true" :value="contratosStore._header.MotivosAutorizacion" class="text-xs" tableStyle="min-width: 50rem">
          <Column field="Nombre" header="Motivo"></Column>
          <Column field="cobro_cuota_moderadora" header="Cobrar cuota moderadora">
            <template #body="slotProps">
              <div class="flex gap-8">
                <div v-for="category in [{name: 'Sí', value: true}, {name: 'No', value: false}]" :key="category.name" class="flex align-items-center">
                    <RadioButton v-model="slotProps.data.cobro_cuota_moderadora" :inputId="category.name" name="dynamic" :value="category.value" />
                    <label :for="category.value" class="ml-2">{{ category.name }}</label>
                </div>
              </div>
            </template>
          </Column>
          <Column field="cobro_cuota_recuperacion" header="Cobrar cuota recuperación">
            <template #body="slotProps">
              <div class="flex gap-8">
                <div v-for="category in [{name: 'Sí', value: true}, {name: 'No', value: false}]" :key="category.name" class="flex align-items-center">
                    <RadioButton v-model="slotProps.data.cobro_cuota_recuperacion" :inputId="category.name" name="dynamic" :value="category.value" />
                    <label :for="category.value" class="ml-2">{{ category.name }}</label>
                </div>
              </div>
            </template>
          </Column>
          <Column header="Acciones">
            <template #body="slotProps">
              <Button @click="eliminarMotivoAutorizacion(slotProps.data)" severity="danger" icon="pi pi-trash"></Button>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </section>
</template>
<script setup>
import { onMounted, ref, defineProps, watch } from 'vue'
import { useContratosStore } from '../../../stores/contratos.store'
// Props
const props = defineProps({
  motivosPadre: {
    type: Array,
    inheritAttrs: false
  }
})
// Ref
const listadoMotivosAutorizacion = ref()
const motivoAutorizacionSeleccionado = ref()
// Stores
const contratosStore = useContratosStore()
// Methods
const cambioModalidadContrato = () => {
  contratosStore.header.MotivosAutorizacion = []
  listadoMotivosAutorizacion.value = contratosStore._listado_motivos_autorizacion.filter(a => a.CodModContrato === contratosStore._modalidad_contrato_seleccionada)
}
const cambioMotivoAutorizacion = () => {
  contratosStore.header.MotivosAutorizacion.push({
    ...motivoAutorizacionSeleccionado.value,
    cobro_cuota_moderadora: null,
    cobro_cuota_recuperacion: null
  })
  motivoAutorizacionSeleccionado.value = {}
  motivosAutorizacionFiltrados()
}
const motivosAutorizacionFiltrados = () => {
  listadoMotivosAutorizacion.value = []
  for (const i of contratosStore._listado_motivos_autorizacion) {
    if (i.CodModContrato === contratosStore._modalidad_contrato_seleccionada && !contratosStore.header.MotivosAutorizacion.some(a => a.Id === i.Id)) {
      listadoMotivosAutorizacion.value.push(i)
    }
  }
}
const eliminarMotivoAutorizacion = (motivo) => {
  contratosStore.header.MotivosAutorizacion = contratosStore._header.MotivosAutorizacion.filter(a => a.Codigo !== motivo.Codigo)
  motivosAutorizacionFiltrados()
}
watch(props, (n, v) => {
  if (n.motivosPadre.length) {
    listadoMotivosAutorizacion.value = n.motivosPadre
  }
})
onMounted(() => {
  contratosStore.obtenerMotivosAutorizacion()
  motivosAutorizacionFiltrados()
})
</script>
